/* container size */
@media (min-width: 1200px){
    :root{
        --container: 1100px;
    }
    .container{
        max-width: var(--container);
    }
}
@media (min-width: 1280px){
    :root{
        --container: 1180px;
    }
}
@media (min-width: 1350px){
    :root{
        --container: 1280px;
    }
}
@media (min-width: 1600px){
    :root{
        --container: 1380px;
    }
}
@media (min-width: 1680px){
    :root{
        --container: 1480px;
    }
}