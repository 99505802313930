
*{
    margin: 0;
    padding: 0;
    box-sizing: border-box;
  }
  
html {
    scroll-behavior: smooth !important;
    /* font-size: 10px; */
  }
  .text-decoration-none{
    text-decoration: none;
  }
  .hover-text{
    transition: all 0.3s;
  }
  .text-clr-white{
    color: rgba(var(--clr-white), 1);
  }
  .hover-text-light-white:hover{
    color: rgba(var(--clr-white), 0.7);
  }
  .container {
    width: 100%;
    max-width: calc(100vw - 30px);
    margin: 0 auto;
  }
  /* SPACING START */
/* big space */
.section-big-py {
    padding-top: var(--section-big-spacing);
    padding-bottom: var(--section-big-spacing);
  }
  
  .section-big-pt {
    padding-top: var(--section-big-spacing);
  }
  
  .section-big-pb {
    padding-bottom: var(--section-big-spacing);
  }
  
  .section-big-my {
    margin-top: var(--section-big-spacing);
    margin-bottom: var(--section-big-spacing);
  }
  
  .section-big-mt {
    margin-top: var(--section-big-spacing);
  }
  
  .section-big-mb {
    margin-bottom: var(--section-big-spacing);
  }
  /* mid space */
  .section-mid-py {
    padding-top: var(--section-mid-spacing);
    padding-bottom: var(--section-mid-spacing);
  }
  
  .section-mid-pt {
    padding-top: var(--section-mid-spacing);
  }
  
  .section-mid-pb {
    padding-bottom: var(--section-mid-spacing);
  }
  
  .section-mid-my {
    margin-top: var(--section-mid-spacing);
    margin-bottom: var(--section-mid-spacing);
  }
  
  .section-mid-mt {
    margin-top: var(--section-mid-spacing);
  }
  
  .section-mid-mb {
    margin-bottom: var(--section-mid-spacing);
  }
  
  /* sm space */
  .section-sm-py {
    padding-top: var(--section-sm-spacing);
    padding-bottom: var(--section-sm-spacing);
  }
  
  .section-sm-pt {
    padding-top: var(--section-sm-spacing);
  }
  
  .section-sm-pb {
    padding-bottom: var(--section-sm-spacing);
  }
  
  .section-sm-my {
    margin-top: var(--section-sm-spacing);
    margin-bottom: var(--section-sm-spacing);
  }
  
  .section-sm-mt {
    margin-top: var(--section-sm-spacing);
  }
  
  .section-sm-mb {
    margin-bottom: var(--section-sm-spacing);
  }
  
  /* SPACING END */
  .img-fluid{
    max-width: 100%;
    height: auto;
  }
  .radius-inherit{
    border-radius: inherit;
  }
  .heading-text {
    font-size: var(--text-xxl);
    font-weight: 700;
    line-height: 112%;
  }
  
  .title-text {
    font-size: var(--text-xl);
    font-weight: 700;
    line-height: 118%;
  }
  .paragraph-text,
  .text.paragraph-text {
    font-size: var(--text-sm);
    font-weight: 500;
    line-height: 170%;
  }
  /* buton css start */
  .custom-btn{
    min-width: 120px;
    min-height: 50px;
    padding: 5px 15px;
    background-color: rgba(var(--clr-white), 1);
    border: 2px solid rgba(var(--clr-white), 1);
    color: rgba(var(--clr-black), 1);
    font-weight: 600;
    font-size: 20px;
    transition: var(--transition-smooth);
    border-radius: 5px;
    display: flex;
    align-items: center;
    justify-content: center;
  }
  .custom-btn:hover{
    background-color: rgba(var(--clr-white), 0.5);
  }
  /* form-control css start */
  .form-control.custom-input{
    min-height: 45px;
    width: 100%;
    background-color: #fff;
    padding: 5px 15px;
    font-size: 16px;
    font-weight: 500;
  }
  /* form-control css end */
  /* FONT-SIZES START */
.h1-font.h1 {
  font-size: var(--text-xxl);
}

.h2-font.h2 {
  font-size: var(--text-xl);
}

.h3-font.h3 {
  font-size: var(--text-lg);
}

.h4-font.h4 {
  font-size: var(--text-md);
}

.h5-font.h5 {
  font-size: var(--text-sm);
}

.h5.small{
  font-size: var(--text-extra-sm);
}

.h5-font.h5-big {
  font-size: 28px;
}

.h6-font.h6 {
  font-size: 24px;
}

.h6.small {
  font-size: 20px;
}

.p.big {
  font-size: 18px;
}

.p-font.p {
  font-size: 16px;
}

.p.small {
  font-size: 14px;
}

.p.xsmall {
  font-size: 12px;
}

/* FONT-SIZES END */
.lh-base{
  line-height: 130%;
}