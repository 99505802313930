:root{
    /* color */
    --clr-beagle-brown: #926732;
    --clr-black-rgb: 0,0,0;
    --clr-black: var(--clr-black-rgb);
    --clr-white-rgb: 255,255,255;
    --clr-white: var(--clr-white-rgb);
    /* spacing style */
    --section-big-spacing: 100px;
    --section-mid-spacing: 70px;
    --section-sm-spacing: 32px;
    --section-xy-space: 12px;
    /* header height */
    --header-height: 105px;
     /* font size */
    --text-xxl: 60px;
    --text-xl: 50px;
    --text-lg: 42px;
    --text-extra-lg: 34px;
    --text-md: 30px;
    --text-extra-md: 28px;
    --text-sm: 20px;
    --text-extra-sm: 18px;
    /* transition */
    --transition-smooth: all 0.3s;
}